<template>
  <div class="auth-wrapper">
    <pwd-login
      v-show="actived===1"
      :actived.sync="actived"
    ></pwd-login>
    <reset-pwd
      v-show="actived===2"
      :actived.sync="actived"
    ></reset-pwd>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import axios from 'axios'
import { getVuetify } from '@core/utils'
import PwdLogin from './pwdLogin.vue'
import ResetPwd from './resetPwd.vue'

export default {
  components: {
    ResetPwd,
    PwdLogin,

  },
  data() {
    return {
      isLogin: false,
    }
  },
  mounted() {
  },
  methods: {
  },
  setup() {
    const ssoToken = localStorage.getItem('ssoToken')
    // eslint-disable-next-line no-param-reassign
    if (ssoToken) {
      axios.defaults.headers.Authorization = `${ssoToken}`
    }
    const actived = ref(1)
    const account = ref('')
    const password = ref('')

    const $vuetify = getVuetify()
    $vuetify.theme.dark = false

    function change() {
      switch (actived.value) {
        case 1:
          actived.value = 3
          break
        case 2:
          actived.value = 1
          break
        case 3:
          actived.value = 1
          break
        case 4:
          actived.value = 1
          break
        default:
          break
      }
    }

    return {
      change,
      actived,
      account,
      password,
    }
  },
}
</script>

<style lang="scss" scoped>
.auth-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* 加载背景图 */
    background-image: url("~@/assets/images/pages/login/bg.png");
    /* 背景图垂直、水平均居中 */
    background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
    background-attachment: fixed;
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    /* 设置背景颜色，背景图加载过程中会显示背景色 */
    background-color: #464646;
}
</style>
