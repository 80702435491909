<template>
  <div class="card">
    <Disclaimers
      :mobile="mobile"
      :name="nickname"
      :password="password"
      :is-disclaimers-sidebar-active.sync="isDisclaimersSidebarActive"
    ></Disclaimers>
    <v-row
      class="d-flex flex-nowrap"
    >
      <v-col
        md="6"
        cols="0"
        class="card-left d-none d-md-block"
      >
        <v-card-title class="titleLef">
          您好，欢迎来到小五出海通
        </v-card-title>
        <div style="width: 80px;height: 4px;background: #AD6BFC;margin-top: 40px;margin-left: 19px;"></div>
        <v-card-text
          class="cardText"
          style="padding-top: 50px"
        >
          <div style="padding-left: 35px">
            每天有超过3000位优秀的创作者
          </div>
          <div style="padding-left: 112px">
            通过小五平台向全球输出高质量的内容...
          </div>
        </v-card-text>
        <v-img
          class="codeLogo"
          src="@/assets/images/pages/login/code.png"
          style="margin-top: 43px"
        ></v-img>
        <span class="detail">扫描二维码进入微信小程序可确认账单</span>
      </v-col>
      <v-col
        md="5"
        cols="12"
        class="d-block card-right pl-9 pr-9"
      >
        <v-img
          class="brand-logo"
          src="@/assets/images/pages/login/logo.png"
        ></v-img>
        <v-card-text
          class="pl-4 pr-4"
          style="padding-top: 0"
        >
          <v-form
            ref="form"
            v-model="valid"
          >
            <!-- nickname -->
            <v-text-field
              v-if="isShowNickName"
              id="nickname"
              v-model="nickname"
              :rules="isShowNickName===true?[validators.required]:[]"
              :error-messages="errName"
              :validate-on-blur="true"
              outlined
              dense
              class="mt-9"
              label="用户名"
              placeholder="请输入用户名"
              @change="clearErrMsg"
            />
            <!-- email -->
            <v-text-field
              id="account"
              v-model="account"
              :rules="[validators.required]"
              :error-messages="errPhone"
              :validate-on-blur="true"
              outlined
              dense
              label="账号名"
              class="inputColor mt-4 mb-4"
              placeholder="请输入邮箱/手机号"
              @change="clearErrMsg"
            />

            <!-- password -->
            <v-text-field
              id="password"
              v-model="password"
              :rules="[validators.required]"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              :error-messages="errMsg"
              :validate-on-blur="true"
              outlined
              dense
              label="密码"
              class="inputColor"
              placeholder="请输入密码"
              @click:append="isPasswordVisible = !isPasswordVisible"
              @change="clearErrMsg"
            />

            <small class="error--text">{{ tipsMsg }}</small>
            <div class="d-flex justify-end">
              <a @click="change(2)">
                <small>重置/忘记密码</small>
              </a>
            </div>

            <!-- submit button -->
            <v-btn
              color="primary"
              block
              class="mt-4 mb-1 text-white font-medium-1"
              style="height: 168px"
              @click="login"
            >
              安全登入
            </v-btn>
          </v-form>
        </v-card-text>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import axios from 'axios'
import {
  required,
} from '@core/utils/validation'
import { requestGet } from '@core/utils/form'
import { modalChose, modalFail, toastSuccess } from '@core/utils/prompt'
import { getVuetify } from '@core/utils'
import themeConfig from '@themeConfig'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import config from '../../config'
import Disclaimers from './Disclaimers.vue'

export default {
  components: {
    Disclaimers,
  },
  props: {
    actived: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLogin: false,
    }
  },
  mounted() {
    // 没有code执行校验
    if (!this.$route.query.code) {
      // this.getRedirectUrl()
    }
  },
  methods: {
  },
  setup(props, { emit }) {
    const account = ref('')
    const mobile = ref('')
    const password = ref('')
    const nickname = ref('')
    const errMsg = ref('')
    const errPhone = ref('')
    const errName = ref('')
    const tipsMsg = ref('')
    const valid = ref(false)
    const isDisclaimersSidebarActive = ref(false)
    const isPasswordVisible = ref(false)
    const isShowNickName = ref(false)
    const form = ref(null)

    // const { router } = useRouter()
    const $vuetify = getVuetify()
    const validate = () => {
      form.value.validate()
    }

    function change(num) {
      this.$emit('update:actived', num)
    }

    const clearErrMsg = () => {
      errMsg.value = ''
      errPhone.value = ''
    }

    const login = () => {
      if (valid.value) {
        axios.post(`${config.serviceUrl}/client/login`, { name: account.value, password: password.value })
          .then(response => {
            if (response.data.status === 200) {
              console.log(response.data)
              if (response.data.data.needUserName === 1) {
                modalFail('账号重复，请输入用户名！')
                isShowNickName.value = true
              }
              if (response.data.data.needUpdatePassword === 1) {
                modalChose('登录前请先修改密码！').then(isConfirm => {
                  if (isConfirm.value) {
                    emit('update:actived', 2)
                  }
                })
              }
              if (response.data.data.needUpdatePassword === 2) {
                modalChose('您有半年没有修改过密码，为了您的账号安全，请修改密码！').then(isConfirm => {
                  if (isConfirm.value) {
                    emit('update:actived', 2)
                  }
                })
              }
              if (response.data.data.statement === 0) {
                mobile.value = account.value
                isDisclaimersSidebarActive.value = true

                return
              }
              if (response.data.data.token) {
                localStorage.setItem('ssoToken', response.data.data.token)
                requestGet('/client/loginMemberList').then(res => {
                  localStorage.setItem('userData', JSON.stringify({ ...response.data.data.userData, roles: response.data.data.roles }))
                  localStorage.setItem('userList', JSON.stringify(res.data))
                  localStorage.setItem('userAbility', JSON.stringify([{ action: 'manage', subject: 'all' }]))
                  $vuetify.theme.dark = themeConfig.app.isDark
                  requestGet('/client/userInfo').then(result => {
                    localStorage.setItem('userInfo', JSON.stringify(result.data))
                    toastSuccess('登录成功！')
                    setTimeout(() => {
                      window.location.replace(`${window.location.origin}/project/channel/list`)
                    }, 500)
                  }).catch(error => {
                    console.log(error)
                    modalFail(error.response.data.message || '登录失败！')
                    localStorage.removeItem('ssoToken')
                  })
                }).catch(err => {
                  console.log(err)
                  modalFail(err.response.data.message || '登录失败！')
                  localStorage.removeItem('ssoToken')
                })
              }
            } else {
              modalFail(response.data.message)
            }
          }).catch(error => {
            console.log(error)
            if (error.response.data.data.password) {
              errMsg.value = error.response.data.message
            } else errPhone.value = error.response.data.message
            console.log(error.response.data.message)
          })
      } else {
        validate()
      }
    }

    return {
      form,
      valid,
      validate,
      change,
      account,
      password,
      nickname,
      errPhone,
      errName,
      errMsg,
      tipsMsg,
      mobile,
      isShowNickName,
      isPasswordVisible,
      isDisclaimersSidebarActive,
      login,
      clearErrMsg,

      // validation
      validators: {
        required,
      },

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-left{
  min-height: 610px;
  background: #F8F6FF;
  min-width: 670px;
  border-radius: 30px 0 0 30px;
  box-shadow: 0 9px 9px 0 #6C5C99;

}
.card-right{
  max-width: 480px;
  min-width: 400px;
  background: #FFFFFF;
  border-radius: 0 30px 30px 0;
  box-shadow: 9px 9px 9px 0 #6C5C99;
}
.titleLef{
  height: 31px;
  font-size: 30px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: bold;
  color: #333333;
  margin-top: 40px;
}
.subtitleLef{
  height: 13px;
  font-size: 18px;
  font-family: Microsoft Yi Baiti,math;
  font-weight: 400;
  color: #999999;
  margin-top: 24px;
}
.cardText{
  width: 660px;
  font-size: 20px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.codeLogo{
  margin-left: 230px;
  width: 160px;
  margin-bottom: 19px;
}
.detail{
  margin-left: 195px;
  height: 18px;
  font-size: 14px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.brand-logo{
  margin-top: 137px;
  margin-left: 88px;
  width: 177px;
}
.v-card .v-card-title{
  position: absolute;
  width: 408px;
  height: 144px;
  top: 100px;
  left: 83px;
  font-size: 60px;
  color: black;
  line-height: 100px;
  font-weight: 500;
}
</style>
