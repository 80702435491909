<template>
  <div class="resetCard">
    <div
      class="titleLefTop"
      @click="change(1)"
    >
      <v-img
        class="backLogo"
        src="@/assets/images/pages/login/back.png"
      ></v-img>
      <div class="detail">
        返回密码登录
      </div>
    </div>
    <div>
      <v-stepper
        v-model="step"
        :alt-labels="true"
        style="box-shadow: none"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            确认账号
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="step > 2"
            step="2"
          >
            安全验证
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            重置密码
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="container pt-8">
              <v-form>
                <v-text-field
                  v-model="mobile"
                  outlined
                  :rules="[validators.required, validators.mobileValidator]"
                  :error-messages="errorMobile"
                  style="width: 100%;"
                  class="mb-5"
                  label="手机号"
                  @input="errorMobile=''"
                >
                </v-text-field>
              </v-form>

              <v-btn
                color="primary"
                style="width: 100%;"
                @click="checkMobile"
              >
                下一步
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div
              v-show="!showCode"
              class="container pt-8"
            >
              <div class="errorText">
                为了保护账号安全，需要验证手机有效性
              </div>
              <div style="width: 100%;margin-bottom: 10px">
                点击以下按钮，将会发送验证码到你的手机
                <br>
                {{ mobile.substring(0,3) }}****{{ mobile.substring(mobile.length-4) }}
              </div>
              <v-btn
                color="primary"
                style="width: 100%;"
                @click="isShow = true"
              >
                发送短信
              </v-btn>
              <Vcode
                :show="isShow"
                @success="onSuccess"
                @close="onClose"
              />
            </div>
            <div
              v-show="showCode"
              class="container pt-8"
            >
              <div style="width: 100%;margin-bottom: 10px">
                验证码已发送到手机{{ mobile.substring(0,3) }}****{{ mobile.substring(mobile.length-4) }}
              </div>
              <v-text-field
                v-model="verificationCode"
                outlined
                :rules="[validators.required]"
                :error-messages="errorMsg"
                placeholder="请输入手机验证码"
                @input="errorMsg=''"
              >
                <template #append>
                  <v-chip
                    :disabled="isCountdown"
                    small
                    color="primary"
                    outlined
                    @click="sendMsg"
                  >
                    {{ hint }}
                  </v-chip>
                </template>
              </v-text-field>
              <v-btn
                color="primary"
                @click="checkCode"
              >
                确定
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div
              class="container pt-8"
            >
              <div
                style="width: 100%;margin-bottom: 10px"
                class="detail"
              >
                最少八个字符，至少一个大写字母，一个小写字母和一个数字
              </div>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                  v-model="newPwd"
                  :rules="[validators.required,validators.passwordValidator]"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  outlined
                  placeholder="请输入新密码"
                  class="mb-2"
                  @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                ></v-text-field>
                <v-text-field
                  v-model="comfirmPwd"
                  :rules="[validators.required,validators.confirmedValidator(newPwd,comfirmPwd)]"
                  :type="isCPasswordVisible ? 'text' : 'password'"
                  :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  outlined
                  placeholder="请确认新密码"
                  class="mb-2"
                  @click:append="isCPasswordVisible = !isCPasswordVisible"
                ></v-text-field>
              </v-form>
              <v-btn
                color="primary"
                style="width: 100%;"
                @click="resetPwd"
              >
                重置密码
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import {
  mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import axios from 'axios'
import {
  required, passwordValidator, confirmedValidator, mobileValidator,
} from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import Vcode from 'vue-puzzle-vcode'
import config from '../../config'

export default {
  components: {
    Vcode,
  },
  props: {
    actived: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLogin: false,
    }
  },
  mounted() {
    // 没有code执行校验
    if (!this.$route.query.code) {
      // this.getRedirectUrl()
    }
  },
  setup(props, { emit }) {
    const ssoToken = localStorage.getItem('ssoToken')
    // eslint-disable-next-line no-param-reassign
    if (ssoToken) {
      axios.defaults.headers.Authorization = `${ssoToken}`
    }
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const account = ref('')
    const password = ref('')
    const step = ref(1)
    const mobile = ref('')
    const errorMsg = ref('')
    const errorMobile = ref('')
    const hint = ref('重新发送')
    const verificationCode = ref('')
    const isShow = ref(false)
    const isCountdown = ref(false)
    const showCode = ref(false)
    const newPwd = ref('')
    const comfirmPwd = ref('')
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    function change(num) {
      emit('update:actived', num)
    }

    let num = 60
    function timeoutChangeStyle() {
      // 到达0秒后 重置时间，去除样式
      if (num === 0) {
        num = 60
        hint.value = '重新发送'
        isCountdown.value = false

        // 时间到达后清除样式
      } else {
        isCountdown.value = true
        hint.value = `${num}s后再次发送`
        // eslint-disable-next-line no-implied-eval
        setTimeout(() => {
          timeoutChangeStyle()
        }, 1000)
      }
      num -= 1
    }

    const onSuccess = msg => {
      console.log(msg)
      isShow.value = false // 通过验证后，需要手动关闭模态框
      showCode.value = true
      toastSuccess('验证码已发送！')
      timeoutChangeStyle()
      axios.post(`${config.serviceUrl}/person/sendSMS/${mobile.value}`)
        .then(response => {
          if (response.data.status === 200) {
            console.log(response.data)
          }
        }).catch(error => {
          modalFail(error.response.data.message)
          console.log('error')
        })
    }

    const sendMsg = () => {
      toastSuccess('验证码已发送！')
      timeoutChangeStyle()
      axios.post(`${config.serviceUrl}/person/sendSMS/${mobile.value}`)
        .then(response => {
          if (response.data.status === 200) {
            console.log(response.data)
          }
        }).catch(error => {
          modalFail(error.response.data.message)
          console.log('error')
        })
    }

    const onClose = () => {
      isShow.value = false
      modalFail('验证失败，请重新验证！')
    }

    const checkMobile = () => {
      if (mobileValidator(mobile.value) === true) {
        step.value = 2
      } else {
        errorMobile.value = '手机号填写错误！'
      }
    }

    const checkCode = () => {
      if (verificationCode.value) {
        axios.post(`${config.serviceUrl}/person/verify?mobile=${mobile.value}&code=${verificationCode.value}`)
          .then(response => {
            if (response.data.status === 200) {
              console.log(response.data)
              step.value = 3
            }
          }).catch(error => {
            if (error.response.data.message) {
              errorMsg.value = error.response.data.message
            }

            // modalFail(error.response.data.message)
            console.log('error')
          })
      }
    }

    const resetPwd = () => {
      if (valid.value) {
        axios.post(`${config.serviceUrl}/person/resetPassword?newPassword=${newPwd.value}&newPasswordVo=${comfirmPwd.value}&mobile=${mobile.value}`)
          .then(response => {
            if (response.data.status === 200) {
              toastSuccess('密码重置成功，请重新登录！')
              emit('update:actived', 1)
            }
          }).catch(error => {
            console.log(error)
            modalFail(error.response.data.message)
          })
      } else {
        validate()
      }
    }

    return {
      valid,
      form,
      validate,
      change,
      account,
      password,
      step,
      mobile,
      errorMsg,
      errorMobile,
      hint,
      isShow,
      isCountdown,
      showCode,
      verificationCode,
      newPwd,
      comfirmPwd,
      isNewPasswordVisible,
      isCPasswordVisible,
      onSuccess,
      onClose,
      checkMobile,
      checkCode,
      resetPwd,
      sendMsg,

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      // validation
      validators: {
        required, passwordValidator, confirmedValidator, mobileValidator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.resetCard{
  width: 1100px;
  height: 610px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 9px 9px 0px #6C5C99;
  border-radius: 30px 30px 30px 30px;
}
.titleLefTop{
  width: 121px;
  height: 21px;
  margin-top: 50px;
  margin-left: 50px;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
}
.backLogo{
  width: 23px;
  height: 21px;
}
.detail{
  margin-left: 14px;
  height: 21px;
  font-size: 14px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  color: #333333;
}
.container{
  width: 460px;
}
.errorText{
  width: 500px;
  font-size: 26px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  color: #FD4545;
  line-height: 40px;
  margin-bottom: 12px;
}
</style>
