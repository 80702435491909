<template>
  <v-dialog
    :value="isDisclaimersSidebarActive"
    persistent
    width="900"
    @input="(val) => $emit('update:is-disclaimers-sidebar-active', val)"
  >
    <v-card>
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">免责声明</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-disclaimers-sidebar-active',false)"
        >
          <v-icon
            color="black"
            size="22"
          >
            {{ mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-card-title class="mt-n5 mb-n2 justify-center">
          网站免责声明
        </v-card-title>
        <v-container>
          欢迎使用小五产品及服务！<br>
          本声明版本更新日期为2023年10月11日，将于2023年10月11日正式生效。如您对本声明有任何疑问，可与我司客服人员沟通咨询您的疑问。您直接或通过各类方式间接使用小五科技内容结算平台服务和数据的行为，便视为您接受了以下声明，请仔细阅读以下内容。如果您不同意以下任何内容，请立刻停止访问或停止使用本小五科技内容结算平台服务。<br>
          1．您明确同意使用杭州小五和她的兄弟们科技有限公司（以下简称“杭州小五”）的网络服务，即小五科技内容结算平台（以下简称“平台”）。<br>
          2．若您提供的图片、材料等存在侵犯第三人的合法权益或违反国家规定的法律法规的事由，杭州小五不因此承担任何连带责任。如您给任何第三方造成的损失，您应承担赔偿责任。<br>
          3．您应当妥善保管平台的账号信息，避免账号及您信息的泄露，如因您的原因导致账号及个人信息泄露，杭州小五不承担任何法律责任。<br>
          4．杭州小五不担保或保证网络服务一定能满足您的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作任何担保或保证。杭州小五对于您使用本公司提供的网络服务或无法使用本公司提供的网络服务所导致的计算机软、硬件的损害或发生资料的流失等任何直接、间接、附带、特别、衍生的损害不承担任何责任。<br>
          5．对于因不可抗力或杭州小五不能控制的原因造成的网络服务中断或其他缺陷，杭州小五不承担任何责任，但将尽力减少因此而给您造成的损失和影响。<br>
          6．杭州小五对于任何自本网站而获得的他人的信息、内容或者广告宣传等任何资讯（以下统称“信息”），不保证真实、准确和完整性。如果任何单位或者个人通过上述“信息”而进行任何行为，须自行甄别真伪和谨慎预防风险。否则，无论何种原因，杭州小五不对任何非与本网站直接发生的行为承担任何直接、间接、附带或衍生的连带损失和责任。<br>
          7. 您同意并确认，杭州小五将采集您的姓名、手机号码、邮箱地址用于为您开设平台账户，确认收益账单。<br>
          8．除平台另有明确说明或者法律法规规定外，平台中您提供的图片、文字等数据、资料的知识产权归信息提供者所有。您同意，平台有权（全部或部分）使用、复制、修订、改编、发布、翻译、分发、执行和展示您的资料数据或制作其派生、汇编作品。<br>
          9.对于因平台合理控制范围以外的原因，包括但不限于自然灾害、罢工或骚乱、暴动、战争行为、政府行为等，致使平台延迟或未能履约的，平台不因此承担任何责任。<br>
          10.如因系统维护或升级而需暂停服务时，平台将事先公告。若因线路及非平台控制范围的硬件故障或其他不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，平台不承担任何责任。<br>
          11.任何单位或个人认为通过平台的内容可能涉嫌侵犯其合法权益，应该及时向平台书面反馈，并提供身份证明、权属证明及详细侵权情况证明，在收到上述法律文件后，平台将会尽快处理，如平台确有侵权行为，则平台将移除、下架被控侵权内容。<br>
          12.本声明的最终解释权归杭州小五和她的兄弟们科技有限公司所有。<br>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          outlined
          @click="disagree"
        >
          不同意
        </v-btn>
        <v-btn
          color="info"
          @click="agree"
        >
          同意
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
} from '@mdi/js'
import axios from 'axios'
import themeConfig from '@themeConfig'
import { getVuetify } from '@core/utils'
import { modalChose, modalFail, toastSuccess } from '@core/utils/prompt'
import { requestGet } from '@core/utils/form'
import config from '../../config'

export default {
  model: {
    prop: 'isDisclaimersSidebarActive',
    event: 'update:is-details-sidebar-active',
  },
  props: {
    isDisclaimersSidebarActive: {
      type: Boolean,
      required: true,
    },
    mobile: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    password: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const disagree = () => {
      emit('update:is-disclaimers-sidebar-active', false)
    }

    const $vuetify = getVuetify()

    const agree = () => {
      axios.post(`${config.serviceUrl}/client/login`, { name: props.mobile, password: props.password, statement: 1 })
        .then(response => {
          if (response.data.status === 200) {
            console.log(response.data)
            if (response.data.data.needUpdatePassword === 1) {
              modalChose('登录前请先修改密码！').then(isConfirm => {
                if (isConfirm.value) {
                  emit('update:actived', 2)
                }
              })
            }
            if (response.data.data.needUpdatePassword === 2) {
              modalChose('您有半年没有修改过密码，为了您的账号安全，请修改密码！').then(isConfirm => {
                if (isConfirm.value) {
                  emit('update:actived', 2)
                }
              })
            }
            if (response.data.data.token) {
              localStorage.setItem('ssoToken', response.data.data.token)
              requestGet('/client/loginMemberList').then(res => {
                localStorage.setItem('userData', JSON.stringify({ ...response.data.data.userData, roles: response.data.data.roles }))
                localStorage.setItem('userList', JSON.stringify(res.data))
                localStorage.setItem('userAbility', JSON.stringify([{ action: 'manage', subject: 'all' }]))
                $vuetify.theme.dark = themeConfig.app.isDark
                toastSuccess('登录成功！')
                window.location.href = '/'
              }).catch(err => {
                console.log(err)
                modalFail(err.response.data.message || '登录失败！')
                localStorage.removeItem('ssoToken')
              })
            }
          }
        }).catch(error => {
          console.log(error)
          console.log(error.response.data.message)
        })
    }

    return {
      disagree,
      agree,
      mdiClose,
    }
  },
}
</script>

<style scoped>
</style>
